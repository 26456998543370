/* eslint-disable import/first */
import React from "react";
import { ROUTES } from "../constants";
import { useAuth0 } from "@auth0/auth0-react";

import async from "../components/Async";

import {
  Activity,
  Home,
  Monitor,
  Users,
  Map as MapIcon,
  FileText,
  HelpCircle,
  BarChart2,
  Settings,
  Smartphone,
} from "react-feather";

import Landing from "../pages/presentation/Landing";

// TODO MAYBE LAZY IMPORT
import PublicMap from "../pages/publicMap";
import Default from "../pages/dashboards/Default";
import TimeSeriesComparison from "../pages/dataAccess/timeSeries/TimeSeriesComparison";
import SitesSummaryTable from "../pages/dataAccess/Reports/SitesSummaryTable";
import AdminResources from "../pages/dataAccess/AdminResources";
import ScadaStations from "../pages/dataAccess/timeSeries/ScadaStations";
import RechargeStations from "../pages/dataAccess/timeSeries/RechargeStations";
import CanalPiezometers from "../pages/dataAccess/timeSeries/CanalPiezometers";
import DeveloperVisibilityFilter from "../components/DeveloperVisibilityFilter";
import DeveloperGuard from "../components/DeveloperGuard";
import Mobile from "../pages/dashboards/Mobile";
import VancilOps from "../pages/dataAccess/timeSeries/VancilOps";
import RiversideOps from "../pages/dataAccess/timeSeries/RiversideOps";
import SmsHelp from "../pages/dataAccess/systemManagement/SmsHelp";
import AlertsManagement from "../pages/dataAccess/systemManagement/AlertsManagement";
import Contacts from "../pages/dataAccess/systemManagement/Contacts";
import Groups from "../pages/dataAccess/systemManagement/Groups";
import GroupsToContacts from "../pages/dataAccess/associations/GroupsToContacts";
import ImportsConfiguration from "../pages/dataAccess/systemManagement/ImportsConfiguration";
import LocationsSetup from "../pages/dataAccess/systemManagement/LocationsSetup/LocationsSetup";
import WaterbodiesSetup from "../pages/dataAccess/systemManagement/WaterbodiesSetup/WaterbodiesSetup";
import RiversideReservoir from "../pages/dataAccess/controls/RiversideReservoir";
import Tune from "@material-ui/icons/Tune";
import Lock from "@material-ui/icons/Lock";
import AdminVisibilityFilter from "../components/AdminVisibilityFilter";
import AdminGuard from "../components/AdminGuard";
import GateStations from "../pages/dataAccess/timeSeries/GateStations";
import RiversideInlet from "../pages/dataAccess/timeSeries/RiversideInlet";
import FixedStationsPage from "../pages/dataAccess/timeSeries/FixedStationsPage";
import AuthGuard from "../components/AuthGuard";
import FixedStationsGuard from "../components/FixedStationsGuard";
const Account = async(() => import("../pages/pages/Account"));
const Profile = async(() => import("../pages/pages/Profile"));

// LANDING
const landingRoute = {
  id: "Landing Page",
  path: "/",
  header: "Docs",
  icon: <Monitor />,
  component: Landing,
  children: null,
};

// DASHBOARDS
const mainDashRoute = {
  header: "Dashboards",
  id: "Operations Dashboard",
  path: "/dashboard",
  icon: <Home />,
  component: Default,
  containsHome: true,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const mobileDashRoute = {
  id: "Mobile Dashboard",
  path: "/mobile-dashboard",
  icon: <Smartphone />,
  component: Mobile,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

// DATA ACCESS
const mapExplorerRoute = {
  header: "Data Access",
  id: "Map Explorer",
  icon: <MapIcon />,
  path: "/data-access/map-explorer",
  name: "Map Explore",
  component: PublicMap,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const timeSeriesComparisonsRoute = {
  id: "Time Series Comparisons",
  icon: <Activity />,
  path: "/data-access/time-series-comparisons",
  name: "Time Series Comparisons",
  component: TimeSeriesComparison,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const systemGraphsRoutes = {
  id: "System Graphs",
  open: true,
  icon: <BarChart2 />,
  children: [
    {
      path: "/data-access/system-graphs/scada-stations",
      name: "SCADA Stations",
      component: ScadaStations,
      guard: AdminGuard,
    },
    {
      path: "/data-access/system-graphs/recharge-stations",
      name: "Recharge Stations",
      component: RechargeStations,
      guard: AdminGuard,
    },
    {
      path: "/data-access/system-graphs/vancil-operations",
      name: "Vancil Operations",
      component: VancilOps,
      guard: AdminGuard,
    },
    {
      path: "/data-access/system-graphs/riverside-operations",
      name: "Riverside Operations",
      component: RiversideOps,
      guard: AdminGuard,
    },
    {
      path: "/data-access/system-graphs/canal-piezometers",
      name: "Canal Piezometers",
      component: CanalPiezometers,
      guard: AdminGuard,
    },
    {
      path: "/data-access/system-graphs/gate-stations",
      name: "Gate Stations",
      component: GateStations,
      guard: AdminGuard,
    },
    {
      path: "/data-access/system-graphs/riverside-inlet",
      name: "Riverside Inlet",
      component: RiversideInlet,
      guard: AdminGuard,
    },
  ],
  visibilityFilter: AdminVisibilityFilter,
};

// this only adds the route. the sidebar is created dynamically based on role/app metadata
const fixedStationsSystemGraphsRoutes = {
  id: "System Graphs",
  icon: <Lock />,
  path: "/data-access/system-graphs/fixed-stations/:displayId",
  name: "Fixed Stations",
  component: FixedStationsPage,
  guard: FixedStationsGuard,
};

// SYSTEM MANAGEMENT
const SystemManagementRoutes = {
  id: "System Management",
  icon: <Settings />,
  children: [
    {
      path: "/data-access/system-management/alerts-management",
      name: "Alerts Management",
      component: AlertsManagement,
      guard: AdminGuard,
    },
    {
      path: "/data-access/system-management/contacts",
      name: "Contacts",
      component: Contacts,
      guard: AdminGuard,
    },
    {
      path: "/data-access/system-management/groups",
      name: "Groups",
      component: Groups,
      guard: AdminGuard,
    },
    {
      path: "/data-access/system-management/associate-contacts-and-groups",
      name: "Associate Groups with Contacts",
      component: GroupsToContacts,
      guard: AdminGuard,
    },
    {
      path: "/data-access/system-management/locations-setup",
      name: "Locations Setup",
      component: LocationsSetup,
      guard: AdminGuard,
    },
    {
      path: "/data-access/system-management/waterbodies-setup",
      name: "Waterbodies Setup",
      component: WaterbodiesSetup,
      guard: AdminGuard,
    },
    {
      path: "/data-access/system-management/imports-configuration",
      name: "Imports Configuration",
      component: ImportsConfiguration,
      guard: AdminGuard,
    },
    {
      path: "/data-access/system-management/sms-help",
      name: "SMS Help",
      component: SmsHelp,
      guard: AdminGuard,
    },
  ],
  visibilityFilter: AdminVisibilityFilter,
};

const siteSummaryRoute = {
  id: "Site Summary Table",
  icon: <FileText />,
  path: "/data-access/site-summary-table",
  name: "Site Summary Table",
  component: SitesSummaryTable,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const ControlsRoute = {
  id: "Controls",
  icon: <Tune />,
  children: [
    {
      path: "/data-access/controls/riverside-reservoir",
      name: "Riverside Reservoir",
      component: RiversideReservoir,
      guard: AdminGuard,
    },
  ],
  visibilityFilter: AdminVisibilityFilter,
};

const resourcesRoute = {
  id: "Admin Resources",
  icon: <HelpCircle />,
  path: "/data-access/admin-resources",
  name: "Admin Resources",
  component: AdminResources,
  guard: DeveloperGuard,
  visibilityFilter: DeveloperVisibilityFilter,
};

// ACCOUNT
const accountRoutes = {
  id: "Account",
  path: "/account",
  name: "Account",
  header: "Pages",
  icon: <Users />,
  component: Account,
  guard: AuthGuard,
  children: [
    {
      path: ROUTES.USER_PROFILE,
      name: "Profile",
      component: Profile,
    },
    {
      path: "/auth/logout",
      name: "Logout",
      component: function Logout() {
        const { logout } = useAuth0();
        logout();
      },
    },
  ],
};

export const dashboardLayoutRoutes = [
  mainDashRoute,
  mobileDashRoute,
  mapExplorerRoute,
  timeSeriesComparisonsRoute,
  systemGraphsRoutes,
  SystemManagementRoutes,
  siteSummaryRoute,
  ControlsRoute,
  resourcesRoute,
  fixedStationsSystemGraphsRoutes,
  accountRoutes,
];

export const dashboardMaxContentLayoutRoutes = [mapExplorerRoute];

export const authLayoutRoutes = [accountRoutes];

export const presentationLayoutRoutes = [landingRoute];

export const fullscreenMapRoutes = [];

export const sidebarRoutes = [
  mainDashRoute,
  mobileDashRoute,
  mapExplorerRoute,
  timeSeriesComparisonsRoute,
  systemGraphsRoutes,
  SystemManagementRoutes,
  siteSummaryRoute,
  ControlsRoute,
  resourcesRoute,
];
